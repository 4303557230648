<template>
  <div class="d-flex flex-md-row flex-column-reverse">
    <div class="col-md-9 nopadding">
      <b-input-group class="input-group-merge mb-2 mt-2">
        <b-input-group-prepend
          class="no-border"
          is-text
        >
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="search"
          type="search"
          class="no-border"
          :placeholder="$t('Search product...')"
        />
      </b-input-group>
      <no-access-info-card v-if="showNoAccessInfoCard" />
      <div v-else>
        <div v-if="products">
          <div>
            <product-card
              v-for="product in products.data"
              :key="product.id"
              :product="product"
            />
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="
                products.meta.pagination.total > products.meta.pagination.per_page
              "
              v-model="pageNumber"
              :per-page="products.meta.pagination.per_page"
              :total-rows="products.meta.pagination.total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        <div
          v-if="!products || !products.data.length"
          class="d-flex align-items-center justify-content-center"
        >
          <b-img
            src="@/assets/images/pages/course/empty-list.svg"
            class="d-inline-block mt-5"
          />
        </div>
      </div>
    </div>
    <div class="col-md-3 pl-md-1 pr-md-0 p-0">
      <b-card
        class="mt-2  mb-0"
        body-class="p-1"
      >
        <div class="mb-1">
          <h4 class="font-weight-bolder">
            {{ $t("Stock") }}
          </h4>
          <b-form-checkbox
            v-for="item in availability"
            :key="item.value"
            v-model="filterByStatus"
            :value="item.id"
            class="custom-control-primary mt-1"
          >
            <h5>{{ $t(item.value) }}</h5>
          </b-form-checkbox>
        </div>
        <div class="mb-1">
          <h4 class="font-weight-bolder">
            {{ $t("Sort") }}
          </h4>
          <b-form-checkbox
            v-for="priceCategory in priceCategories"
            :key="priceCategory.id"
            v-model="direction"
            :value="priceCategory.value"
            class="custom-control-primary mt-1"
          >
            <h5>{{ $t(priceCategory.name) }}</h5>
          </b-form-checkbox>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BImg,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'

import { debounce } from 'vue-debounce'
import ProductCard from '@/components/marketplace/ProductCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BPagination,
    BImg,
    BCard,
    BFormCheckbox,

    ProductCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      price: 1,
      stock: 1,
      priceCategories: [
        {
          id: 1,
          value: 'asc',
          name: 'Price low-high',
        },
        {
          id: 2,
          value: 'desc',
          name: 'Price high-low',
        },
      ],
      availability: [
        {
          id: 1,
          value: 'In stock',
        },
        {
          id: 0,
          value: 'Out of stock',
        },
      ],
    }
  },
  computed: {
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    products() {
      return this.$store.getters['marketplace/products']
    },
    pageNumber: {
      set(pageNumber) {
        this.$store.commit('marketplace/setPageNumber', pageNumber)
      },
      get() {
        return this.$store.getters['marketplace/pageNumber']
      },
    },
    filterByStatus: {
      set(filterByStatus) {
        this.$store.commit('marketplace/setFilterByStatus', filterByStatus)
      },
      get() {
        return this.$store.getters['marketplace/filterByStatus']
      },
    },
    direction: {
      set(direction) {
        this.$store.commit('marketplace/setDirection', direction)
      },
      get() {
        return this.$store.getters['marketplace/direction']
      },
    },
    query() {
      return this.$store.getters['marketplace/query']
    },
    search: {
      get() {
        return this.$store.getters['marketplace/search']
      },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.$store.commit('marketplace/setSearch', search)
      }, 1000),
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompanyAdmin() {
      return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
    },
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    showNoAccessInfoCard() {
      if (!this.isAuthenticated) return false
      if (this.representACompanyAdmin) return false
      if (!this.checkPermission('MARKET_READ', this.userPermissions)) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    "$route.query.filter": {
      handler() {
        if (!this.showNoAccessInfoCard) {
          this.$store.dispatch('marketplace/fetchProducts')
        }
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.$store.commit(
        'marketplace/setQuery',
        JSON.parse(this.$route.query.filter),
      )
    }
    if (!this.showNoAccessInfoCard) {
      this.$store.dispatch('marketplace/fetchProducts')
    }
  },
  methods: {
    updateQuery(query) {
      this.$router.push({
        path: '/marketplace',
        query: { filter: JSON.stringify(query) },
      }).catch(() => {})
    },
  },
}
</script>
